.userstable {
    background-color: white;
    min-width: 100%;
}

.userstable table {
    table-layout: fixed;
}

.userstable>thead>tr>th {
    text-align: center;
    padding: 8px;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    background: #e3e7f1;
    border-left: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6;
}

.userstable>tbody>tr {
    height: 100px;
}

.userstable td {
    border: 1px solid #dee2e6;
    padding: 15px;
}

.userstable.pitch-decks td:hover {
    .expand-button {
        color: black !important;
    }
}

.userstable.pitch-decks thead th:nth-child(3) {
    position: sticky;
    position: -webkit-sticky;
    left: 0;
    z-index: 2;
}

.userstable.pitch-decks thead th:nth-child(6) {
    position: sticky;
    position: -webkit-sticky;
    left: 12vw;
    z-index: 2;
}

.userstable.pitch-decks tbody td:nth-child(3) {
    position: sticky;
    position: -webkit-sticky;
    left: 0;
    z-index: 1;
    background-color: white;
}

.userstable.pitch-decks tbody td:nth-child(6) {
    position: sticky;
    position: -webkit-sticky;
    left: 12vw;
    z-index: 1;
    background-color: white;
}

.sort-column-button>button {
    color: black !important;
    background-color: transparent !important;
    border-color: transparent !important;
    opacity: 1 !important;
    border-radius: 0.25rem !important;
    font-weight: bold;
    font-size: 16px;
}

.sort-column-button>button:hover {
    background-color: #edeff2 !important;
}

.ellipsis-dropdown .dropdown-toggle::after {
    display: none !important;
}

.data-room-dropdown {
    justify-content: right;
    display: flex;
}

.data-room-header-div {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 50px 0 50px 0;
    justify-content: right;
}

.data-room-image {
    border: solid 1px black;
    height: 500px;
}

.data-room-object {
    border: solid 1px black;
    height: 500px;
    width: inherit;
}