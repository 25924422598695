.basePage {
    background-color: aliceblue;
    min-height: 100%;
    position: relative;
    width: 100%;
    font-family: "AileronRegular", "OpenSansRegular";
}

.fundingBenchmarkTable {
    align-self: center;
    width: 100%;
    border-collapse: collapse;
    border-style: hidden;
    border-spacing: 0;
    font-size: 14px;
}

.fundingBenchmarkTable.results td {
    width: 15%;
    padding: 15px;
}

.fundingBenchmarkTable.view {
    border-collapse: separate;
    border-style: solid;
}

.fundingBenchmarkTable.view,
.fundingBenchmarkTable.results {
    border-style: inherit;
}

.fundingBenchmarkTable.view td {
    width: 14%;
    padding: 15px;
}

.fundingBenchmarkTable.results td:not(:nth-child(1)),
.fundingBenchmarkTable.view td:not(:nth-child(1)) {
    text-align: center;
    padding: 15px;
}

.fundingBenchmarkTable .subHeaderRow td {
    text-align: center;
    padding: 15px;
    font-weight: bold;
}

.fundingBenchmarkTable tbody {
    vertical-align: middle;
}

.fundingBenchmarkTable table,
.fundingBenchmarkTable th,
.fundingBenchmarkTable td {
    border: 1px solid #cdcdcd;
    border-collapse: collapse;
    background-color: white;
}

.fundingBenchmarkTable tr {
    height: 80px;
}

.fundingBenchmarkTable td {
    width: 50%;
}

.fundingBenchmarkTable td:nth-child(1),
.fundingBenchmarkTable.results tr:nth-child(1) {
    font-weight: bold;
}

.fundingBenchmarkTable .form-control {
    margin: 5px;
}

#fundingBenchmark .card {
    width: 100%;
}

#fundingBenchmark a {
    color: #039be5 !important;
    text-decoration: underline;
    cursor: pointer;
}

.buttonsDiv {
    place-content: stretch center;
    align-items: stretch;
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
    padding-top: 20px;
    margin-top: 1.5rem;
    background-color: transparent !important;
}

.simulateButton {
    color: black !important;
    padding: 5px 25px !important;
    font-family: "AileronRegular", "OpenSansRegular";
    font-size: 14px !important;
    font-weight: bold !important;
    background: transparent !important;
    outline: none !important;
    cursor: pointer !important;
    transition: all 0.3s ease !important;
    position: relative !important;
    display: inline-block !important;
    border: 2px solid gainsboro !important;
    border-radius: 0 !important;
    z-index: 1 !important;
    margin-left: 10px !important;
}

.simulateButton.disabled {
    cursor: default !important;
}

.simulateButton:after {
    position: absolute;
    content: "";
    width: 0;
    height: 100%;
    top: 0;
    right: 0;
    z-index: -1;
    background: gainsboro;
    transition: all 0.3s ease;
}

.simulateButton:hover:after {
    left: 0;
    width: 100%;
}

.simulateButton:active {
    top: 2px;
}

.resetButton {
    padding: 5px 25px;
    background: transparent;
    outline: none !important;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
    width: 90px;
    height: 35px;
    line-height: 35px;
    padding: 0;
    border: none;
    margin-right: 10px;
}

.resetButton span {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    font-family: "AileronRegular", "OpenSansRegular";
    font-size: 14px;
    font-weight: bold;
}

.resetButton:before,
.resetButton:after {
    position: absolute;
    content: "";
    height: 0%;
    width: 2px;
    background: gainsboro;
}

.resetButton:before {
    right: 0;
    top: 0;
    transition: all 0.1s ease;
}

.resetButton:after {
    left: 0;
    bottom: 0;
    transition: all 0.1s 0.2s ease;
}

.resetButton:hover:before {
    transition: all 0.1s 0.2s ease;
    height: 100%;
}

.resetButton:hover:after {
    transition: all 0.1s ease;
    height: 100%;
}

.resetButton span:before,
.resetButton span:after {
    position: absolute;
    content: "";
    background: gainsboro;
}

.resetButton span:before {
    left: 0;
    top: 0;
    width: 0%;
    height: 2px;
    transition: all 0.1s 0.1s ease;
}

.resetButton span:after {
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
}

.resetButton span:hover:before {
    width: 100%;
}

.rowSticky tr {
    position: sticky;
    z-index: 2;
}

.rowFirst {
    top: 96px
}

.rowSecond {
    top: 176px
}

.rowThird {
    top: 256px
}

.rowFourth {
    top: 336px
}