#dashboard {
    background-color: aliceblue;
    width: 100%;
    min-height: 120vh;
    margin: 0px;
    font-family: "AileronRegular", "OpenSansRegular";
}

/* statistics cards */

.dashboardsubcardrow {
    display: flex;
    flex-direction: row;
}

.dashboardmaincardrow1 {
    display: flex;
    flex-direction: row;
    padding-left: 20px;
}

.dashboardmaincardrow2 {
    display: none;
}

.dashboardmaincard {
    width: 100%;
    margin: 0 5px;
}

.dashboardmaincards {
    width: 12.5%;
}

.dashboardsubcards {
    width: 50%;
    padding-left: 20px;
}

.dashboardcardheadermain {
    padding-left: 10px;
    font-size: 18px;
    color: #29323a;
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.dashboardcardheader {
    padding-left: 10px;
    font-size: 18px;
    color: #29323a;
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.dashboardcardheader:hover {
    text-decoration: underline;
}

@media only screen and (max-width: 575px) {
    .dashboardcardheader {
        font-size: 12px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 768px) {
    .dashboardcardheader {
        font-size: 14px;
    }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
    .dashboardcardheader {
        font-size: 16px;
    }
}

.dashboardcardlabel {
    font-size: 12px;
    color: slategrey;
}

.dashboardcarddata {
    color: #1f5f8b;
    font-weight: bold;
    font-size: 25px;
}

.dashboardcardlink:hover {
    text-decoration: none;
}

.dashboardcompanycard {
    padding: 50px 0 0 0;
}

.dashboardcompanycard2 {
    padding: 24px 0 30px 0;
}

.dashboardrow {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.dashboardml1 {
    margin-left: .25rem !important;
}

.dashboardmr0 {
    margin-right: 0 !important;
}

.dashboardcardrow {
    position: relative;
    width: 100%;
    padding-right: 5px;
    padding-left: 5px;
}

.dashboardcard {
    padding: 10px;
}

.dashboardfmain {
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 0.35rem;
}

.dashboardf {
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 0.35rem;
    height: 100%;
}

.dashboardf:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.6), 0 6px 20px 0 rgba(0, 0, 0, 0.59);
}

@media only screen and (max-width: 425px) {
    .dashboardf {
        min-height: 150px;
    }

    .dashboardcardheader {
        padding-left: 0;
    }

    .dashboardcardrow {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .dashboardsubcardrow {
        display: flex;
        flex-direction: row;
    }

    .dashboardcardlabel {
        font-size: 10px;
    }

    .dashboardmaincards {
        width: 50% !important;
    }
}

@media only screen and (max-width: 576px) {
    .dashboardcardrow {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .dashboardmaincards {
        width: 33%;
    }

    .dashboardsubcardrow {
        display: flex;
        flex-direction: column;
    }

    .dashboardsubcards {
        width: 100%;
    }

    .dashboardcarddata {
        font-size: 18px;
    }

    .dashboardmaincardrow2 {
        display: flex;
        flex-direction: row;
        padding-left: 20px;
    }

    .dashboardmaincardrow1 {
        display: none;
    }
}

@media only screen and (min-width: 576px) and (max-width: 1199px) {
    .dashboardcardrow {
        flex: 0 0 33%;
        max-width: 33%;
    }

    .dashboardmaincards {
        width: 25%;
    }

    .dashboardcarddata {
        font-size: 20px;
    }
}

@media only screen and (min-width: 576px) {
    .dashboardf {
        min-height: 155px;
    }

    .dashboardcardheader {
        padding-left: 0;
    }
}

@media only screen and (min-width: 768px) {
    .dashboardf {
        min-height: 135px;
    }

    .dashboardcardheader {
        padding-left: 10px;
    }
}

@media only screen and (min-width: 992px) {
    .dashboardf {
        min-height: 140px;
    }
}

@media only screen and (min-width: 1200px) {
    .dashboardf {
        min-height: 175px;
    }

    .dashboardcardrow {
        flex: 0 0 20%;
        max-width: 20%;
    }

    .dashboardcardheader {
        padding-left: 0;
    }
}

@media (min-width: 1400px) {
    .dashboardf {
        min-height: 150px;
    }

    .dashboardcardheader {
        padding-left: 10px;
    }
}

@media (min-width: 1800px) {
    .dashboardf {
        min-height: 125px;
    }
}

/* quick stats | do not delete yet */

.table-data {
    vertical-align: top;
}

.db-label {
    font-size: 12px;
    color: slategrey;
}

.db-data {
    font-size: 25px;
    font-weight: bold;
}

.db-header {
    font-size: 18px;
    width: 16%;
}

.db-border {
    border-left: 1px solid lightgrey;
    padding-left: 1%;
}

.dashboardwidget {
    display: block;
    position: relative;
    background: #fff;
    border-radius: 0.35rem;
    box-shadow: 0 23px 20px -20px rgba(0, 0, 0, 0.1), 0 0 15px rgba(0, 0, 0, 0.06);
    padding: 50px;
    margin: 5px;
    height: 100%;
}

.dashboardwidget a,
.dashboardwidget a:hover {
    color: #039be5 !important;
}

.dashboardbody {
    max-width: 100%;
    display: flex;
}

.dashboardbodyleft {
    width: 40%;
    display: block;
}

.dashboardbodyright {
    width: 60%;
    display: block;
}

.dashboardimage {
    text-align: center;
    padding-bottom: 25px;
}

.dashboardimage>img {
    width: 50%;
}

.referralctabutton {
    background-color: #0065ff !important;
    color: white !important;
    border: 1px solid #0065ff !important;
    padding: 6px 15px !important;
    font-weight: bold !important;
    font-family: "AileronRegular", "OpenSansRegular" !important;
    font-size: 14px !important;
    border-radius: 5px !important;
}

@media only screen and (min-width: 1024px) and (max-width: 1199px) {
    .dashboardimage {
        padding-bottom: 42px;
    }

    .dashboardimage>img {
        width: 100%;
    }
}

@media only screen and (min-width: 1200px) {
    .dashboardimage>img {
        width: 94%;
    }
}

@media only screen and (min-width: 1440px) {
    .dashboardimage>img {
        width: 57%;
    }
}

@media only screen and (min-width: 1920px) {
    .dashboardimage>img {
        width: 29%;
    }
}

@media only screen and (max-width: 1024px) {
    .db-header {
        font-size: 16px;
    }
}

@media only screen and (max-width: 768px) {
    .dashboardbody {
        display: block;
    }

    .dashboardbodyleft,
    .dashboardbodyright,
    .dashboardimage>img {
        width: 100%;
    }

    .dashboardbodyright {
        margin-top: 10px;
    }

    .db-border {
        border-left: none;
        padding-left: 0;
    }

    .db-header {
        font-size: 14px;
    }
}

@media only screen and (max-width: 375px) {
    .db-header {
        font-size: 12px;
    }
}

.getintouchformdiv {
    font-family: "AileronRegular", "OpenSansRegular";
    font-size: 16px;
}

.getintouchformheader {
    border-bottom: none;
    padding: 1rem 1rem 0 1rem;
}

.getintouchformbody {
    margin-top: 0 !important;
}

.inputbox {
    background-color: rgba(0, 0, 0, .04);
    border-top: transparent;
    border-right: transparent;
    border-left: transparent;
    padding: 20px !important;
    font-size: 16px !important;
    width: 100%;
    font-family: "AileronRegular", "OpenSansRegular" !important;
}

.orderbutton {
    background-color: royalblue !important;
    color: white !important;
    border: 1px solid royalblue !important;
    padding: 6px 15px !important;
    margin: 15px 0 !important;
    font-weight: bold !important;
    font-family: "AileronRegular", "OpenSansRegular" !important;
    font-size: 14px !important;
    border-radius: 5px !important;
}

.formbox {
    background-color: rgba(0, 0, 0, .04);
    border-top: transparent;
    border-right: transparent;
    border-left: transparent;
    padding: 8px !important;
    font-size: 16px !important;
    width: 100%;
    font-family: "AileronRegular", "OpenSansRegular" !important;
}

.getintouchalert {
    font-size: 13px !important;
    font-weight: bold !important;
    font-family: "AileronRegular", "OpenSansRegular" !important;
    margin-top: 1rem;
}

#sharewithus:hover {
    cursor: pointer;
    text-decoration: underline;
}

#letusknow:hover {
    cursor: pointer;
    text-decoration: underline;
}