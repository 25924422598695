#ideachecker {
    width: 100%;
    background-color: #eff8ff;
    min-height: 120vh;
    position: relative;
    font-family: "AileronRegular", "OpenSansRegular";
}

.ideavalidationheaderdiv {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.ideavalidationtitlediv {
    width: 80%;
    padding: 50px 50px 50px 0;
}

.ideavalidationstatsdiv {
    font-family: "AileronRegular", "OpenSansRegular";
    width: 20%;
    padding: 50px 0 50px 50px;
}

@media (max-width: 768px) {
    .ideavalidationheaderdiv {
        flex-direction: column;
    }

    .ideavalidationtitlediv {
        width: 100%;
        padding: 50px 0 25px 0;
    }

    .ideavalidationstatsdiv {
        width: 100%;
        padding: 25px 0 50px 0;
    }
}

.ideavalidationtext {
    font-family: "AileronRegular", "OpenSansRegular";
    text-align: left;
    font-size: 28px;
    font-weight: bold;
}

.idea-checker-field {
    width: 33%;
}

.idea-checker-field2 {
    width: 50%;
}

.text-muted {
    font-size: 10.5px;
    padding: 0 1em;
}

label,
.text-muted,
.canvas-text,
.canvas-card-header>h5,
.canvas-card-list>ul>li {
    font-family: "AileronRegular", "OpenSansRegular";
}

.analyse-button {
    background-color: #0f9d58 !important;
    color: white !important;
    border: 1px solid #0f9d58 !important;
    padding: 6px 15px !important;
    margin: 0 !important;
    font-weight: bold !important;
    font-family: "AileronRegular", "OpenSansRegular" !important;
    font-size: 14px !important;
    border-radius: 5px !important;
}

.idea-checker-analyse {
    place-content: stretch center;
    align-items: stretch;
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
    margin-top: 1.5rem;
    background-color: transparent !important;
}

.glint-animation {
    position: relative;
}

.glint-animation:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.4);
    -webkit-transition: none;
    -moz-transition: none;
    transition: none;
}

.glint-animation:hover:after {
    width: 120%;
    background-color: rgba(107, 59, 59, 0);
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}

/* ._3vt7_Mh4hRCFbp__dFqBCI input {
  width: 100% !important;
  font-size: 10px !important;
} */

._2iA8p44d0WZ-WqRBGcAuEV {
    height: auto !important;
    overflow: auto !important;
}

.PrivateValueLabel-label-5 {
    font-size: 10px !important;
    color: black !important;
}

.marketreachslider .PrivateValueLabel-label-5 {
    font-size: 10px !important;
    color: black !important;
    padding-left: 30px !important;
}

.jss5 {
    font-size: 10px !important;
    color: black !important;
}

.marketreachslider .jss5 {
    font-size: 10px !important;
    color: black !important;
    padding-left: 30px !important;
}

.PrivateValueLabel-circle-4 {
    color: transparent !important;
    height: 60px !important;
}

.jss4 {
    background-color: transparent !important;
}

.MuiSlider-root {
    color: #011e42 !important;
    width: 80% !important;
}

.MuiSlider-markLabel {
    font-size: 10px !important;
    /* transform: translateX(-80%) rotate(-50deg) !important; */
    top: 35px !important;
    font-family: "AileronRegular", "OpenSansRegular" !important;
}

.MuiSlider-marked {
    margin: 35px 0 20px 0 !important;
}

.detailcompetitorsdiv {
    overflow-x: auto;
    overflow-y: auto;
    white-space: nowrap;
    height: 60vh;
}

.detailnote {
    padding-bottom: 20px;
}

.detailbodytitle {
    font-size: 14px;
    color: slategrey;
    margin-bottom: 0 !important;
}

.detailcompetitorstable table {
    table-layout: fixed;
    width: 100%;
}

.detailcompetitorstable tr:not(:first-child) {
    height: 100px;
}

.detailcompetitorstable th {
    text-align: center;
    white-space: nowrap;
    padding: 8px;
    position: sticky;
    top: 0;
    background: #e3e7f1;
    border-left: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6;
}

.detailcompetitorstable td {
    border: 1px solid #dee2e6;
}

.detailcompetitorstable td:not(:first-child) {
    padding: 15px;
}

.ideacheckerscrollablediv {
    height: 500px;
    overflow-y: auto;
}

.ideacheckertabbody {
    display: flex;
    flex-direction: row;
}

.ideacheckerquestions {
    flex: 0 0 75% !important;
    max-width: 75% !important;
}

.ideacheckerresults {
    flex: 0 0 25% !important;
    max-width: 25% !important;
}

.ideacheckeraccordioncardbody {
    background: #f7f7f7;
    /* padding: 0 !important; */
    background: transparent !important;
    border: none !important;
}

.overflowvisible {
    overflow: visible !important;
    border-radius: .25rem !important;
}

.overflowhidden {
    background: transparent !important;
    border: none !important;
}

.ideacheckerrow {
    padding-bottom: 30px;
    width: 100%;
}

.ideacheckerrow2 {
    padding-bottom: 30px;
    display: flex;
    flex-direction: row;
}

.ideacheckergrid {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    /* padding-bottom: 30px; */
    width: 100%;
    gap: 0px 12px;
}

.ideacheckeritem {
    padding-bottom: 30px;
}

.fontthin {
    font-weight: 100;
}

.fontitalic {
    font-family: AileronItalic;
}

.chip {
    white-space: wrap !important;
}

.ideacheckerplaceholder {
    font-size: 10px !important;
    color: #6c757d !important;
    padding: .375rem .75rem !important;
    margin: 0 !important;
}

.ideacheckernda a,
#ideachecker #notes-points a,
.pitchdeckrecommended a {
    color: #039be5 !important;
}

.ideacheckernda a:hover,
#ideachecker #notes-points a:hover,
.pitchdeckrecommended a:hover {
    color: #039be5 !important;
    text-decoration: underline;
}

.ideacheckerndanote {
    color: #808080;
    font-size: 12px;
    margin-bottom: 1rem;
}

.ideacheckerdropdownnda {
    width: 100% !important;
}

.ideacheckercancelnda {
    background-color: transparent !important;
    color: #039be5 !important;
    border: 1px solid transparent !important;
    padding: 6px 15px !important;
    margin: 0 15px 0 0 !important;
    /* font-weight: bold !important; */
    font-family: "AileronRegular", "OpenSansRegular" !important;
    font-size: 14px !important;
    border-radius: 5px !important;
}

.ideacheckercancelnda:hover {
    text-decoration: underline;
}

.ideacheckersubmitnda {
    background-color: royalblue !important;
    color: white !important;
    border: 1px solid royalblue !important;
    padding: 6px 15px !important;
    margin: 0 !important;
    font-weight: bold !important;
    font-family: "AileronRegular", "OpenSansRegular" !important;
    font-size: 14px !important;
    border-radius: 5px !important;
}

/* table css */

#detail {
    width: 100%;
    background-color: #eff8ff;
    min-height: 100%;
    position: relative;
    font-family: "AileronRegular", "OpenSansRegular";
}

.detailtitlediv {
    width: 100%;
    padding: 50px;
}

.detailtext {
    font-family: "AileronRegular", "OpenSansRegular";
    text-align: left;
    font-size: 28px;
    font-weight: bold;
}

#ideachecker .detaildiv {
    overflow-x: auto;
    overflow-y: auto;
    white-space: nowrap;
    font-family: "AileronRegular", "OpenSansRegular" !important;
    font-size: 16px;
    min-width: 100%;
    min-height: auto;
    max-height: 100vh;
}

.detailtable {
    background-color: white;
}

.detailtable table {
    table-layout: fixed;
    width: 100%;
}

.detailtable tr:not(:first-child) {
    height: 100px;
}

.detailtable thead th:nth-child(2) {
    position: sticky;
    position: -webkit-sticky;
    left: 0;
    z-index: 2;
}

.detailtable thead th:nth-child(4) {
    position: sticky;
    position: -webkit-sticky;
    left: 130px;
    z-index: 2;
}

.detailtable tbody td:nth-child(2) {
    position: sticky;
    position: -webkit-sticky;
    left: 0;
    z-index: 1;
    background-color: white;
}

.detailtable tbody td:nth-child(4) {
    position: sticky;
    position: -webkit-sticky;
    left: 130px;
    z-index: 1;
    background-color: white;
}

.detailtable th {
    text-align: center;
    white-space: nowrap;
    padding: 8px;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    background: #e3e7f1;
    border-left: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6;
}

.detailtable td {
    border: 1px solid #dee2e6;
    padding: 15px;
}

.detailnote {
    padding-bottom: 20px;
}

.detailbodytitle {
    font-size: 14px;
    color: slategrey;
    margin-bottom: 0 !important;
}

.detaillogo {
    width: 100px;
    height: auto;
    padding: 15px;
}

.detailemptydata {
    text-align: center;
}

/* .detailxsdata {
  white-space: normal;
  width: 2vw;
  margin-bottom: 0;
  text-align: center;
}

.detailshortdata {
  white-space: normal;
  width: 8vw;
  margin-bottom: 0;
  text-align: center;
}

.detailmediumdata {
  white-space: normal;
  width: 10vw;
  margin-bottom: 0;
}

.detailmldata {
  white-space: normal;
  width: 15vw;
  margin-bottom: 0;
}

.detaillongdata {
  white-space: normal;
  width: 23vw;
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.detailextralongdata {
  white-space: normal;
  width: 30vw;
  margin-bottom: 0;
}

.detailcutdata {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
} */

.detailbodytitle {
    font-size: 14px;
    font-weight: bold;
    color: slategrey;
    margin-bottom: 0 !important;
}

.detailbodydata {
    font-size: 16px;
}

.detailproductlist {
    padding: 0 0 0 30px;
}

.detailproductlist>li:hover {
    background: none !important;
}

@media (max-width: 576px) {
    /* .detailxsdata {
    width: 6vw;
  }

  .detailshortdata {
    width: 24vw;
  }

  .detailmediumdata {
    width: 30vw;
  }

  .detailmldata {
    width: 45vw;
  }

  .detaillongdata {
    width: 69vw;
  }

  .detailextralongdata {
    width: 90vw;
  } */
}

@media (max-width: 992px) {
    .ideacheckergrid {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        /* padding-bottom: 30px; */
    }

    .ideacheckertabbody {
        flex-direction: column;
    }

    .ideacheckerquestions {
        flex: 0 0 100% !important;
        max-width: 100% !important;
        padding-bottom: 40px;
    }

    .ideacheckerresults {
        flex: 0 0 100% !important;
        max-width: 100% !important;
    }
}

@media (max-width: 768px) {
    .ideacheckerrow2 {
        padding-bottom: 12px;
        flex-direction: column;
        width: 100%;
    }

    .ideacheckergrid {
        padding-bottom: 12px;
        grid-template-columns: 100%;
        width: 100%;
    }

    .idea-checker-field,
    .idea-checker-field2 {
        width: 100%;
        padding-bottom: 30px;
        padding-right: 0 !important;
    }
}

/* recommended readings */

.ideacheckerrecommendedaccordion {
    padding-top: 20px;
}

.ideacheckerrecommendedaccordion>.card {
    border: 1px solid transparent !important;
}

.ideacheckerrecommendedheader {
    display: flex;
    font-size: 16px !important;
    background-color: white !important;
    padding: .75rem 1.25rem;
    border-bottom: none;
    color: grey;
    border: none !important;
    margin: 0 !important;
    font-weight: bold !important;
    font-family: "AileronRegular", "OpenSansRegular" !important;
    border-radius: 5px !important;
    width: 100%;
}

.ideacheckerrecommendedheader:hover {
    background-color: #edeff2 !important;
}

.ideacheckerrecommendedtext {
    width: 90%;
}

.ideacheckerrecommendedarrow {
    width: 10%;
    margin-top: -2px;
    text-align: right;
}