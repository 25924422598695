#changepasswordcontent {
    background: #011e42;
    font-family: "AileronRegular", "OpenSansRegular";
    height: 100vh;
}

.changepasswordtop {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: row;
    padding-top: 3%;
}

.changepasswordalerttop {
    display: block;
    justify-content: center;
    align-content: center;
    margin: auto;
    padding-top: 2%;
    padding-bottom: 3%;
    width: 32%;
}

/* .alert{
    margin-bottom: -2rem !important;
} */

.roiquantlogo {
    width: 180px;
}

.progressbardiv {
    padding: 50px 34% 0 34%;
}

.progressbardiv>div {
    height: 0.5rem;
    background-color: white;
    border-radius: .35rem 0.35rem 0 0;
}

#changepasswordform {
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: row;
    background: #011e42;
}

.changepasswordcard {
    display: block;
    position: relative;
    margin-bottom: 30px;
    background: #fff;
    border-radius: 0 0 .35rem 0.35rem !important;
    box-shadow: 0 23px 20px -20px rgb(0 0 0 / 10%), 0 0 15px rgb(0 0 0 / 6%);
    padding: 40px;
    width: 32%;
    /* margin-top: -0.25rem; */
    border-top: transparent !important;
}

.changepasswordheader {
    margin: 0 0 0 0;
    text-align: center;
    font-size: 28px;
}

.createaccnote {
    text-align: center;
    margin-bottom: 40px;
    font-size: 16px;
}

.createaccnote a {
    color: #039be5 !important;
    text-decoration: underline;
}

.changepasswordnote {
    color: gray;
    display: flex;
    justify-content: center;
    font-family: "AileronItalic", "OpenSansItalic";
}

.inputbox {
    background-color: white !important;
    border-top: transparent;
    border-right: transparent;
    border-left: transparent;
    padding: 20px !important;
    font-size: 16px !important;
    width: 100%;
}

.passwordhint {
    padding-left: 5%;
}

.extranote {
    font-size: 12px;
}

.extranote a {
    color: #039be5 !important;
    text-decoration: underline;
}

.changepasswordbtn {
    background-color: #0065ff !important;
    color: white !important;
    border: 1px solid #0065ff !important;
    padding: 6px 15px !important;
    margin: 15px 0 !important;
    font-weight: bold !important;
    font-family: "AileronRegular", "OpenSansRegular" !important;
    font-size: 16px !important;
    border-radius: 5px !important;
}

.loginnote {
    font-size: 16px;
}

.loginnote a {
    color: #039be5 !important;
    text-decoration: underline;
}

/*validation check area*/
#checksField{
    padding-left: 5px;
    padding-top: 8px;
    font-size: 10px;
    color: gray;
    display: block;
}

p#char, #symbol, #number, #matchtwo, #letter{
    margin-bottom: 3px;
}

p#uppercase{
    padding-bottom: 0px;
}

#checksFieldTwo{
    padding-left: 5px;
    font-size: 10px;
    color: gray;
    display: block;
}

/*toggle password (show/hide)*/
.showpassword, .showcpassword{
    font-size: 10px;
    padding-left: 5px;
    color: #039be5 !important;
}

/*password validator color change*/
.default {
    color:black;
}

.a hover{
   text-decoration: none;
}

.correct {
    color: green;
}

.correct:before {
    position: relative;
    left: -35px;
}

.wrong {
    color: red;
}

.wrong:before {
    position: relative;
    left: -35px;
}

@media only screen and (max-width: 425px) {
    .changepasswordcard {
        width: 80%;
    }

    .progressbardiv {
        padding: 50px 10% 0 10% !important;
    }
}

@media only screen and (min-width: 426px) and (max-width: 576px) {
    .changepasswordcard {
        width: 70%;
    }

    .progressbardiv {
        padding: 50px 15% 0 15% !important;
    }
}

@media only screen and (min-width: 577px) and (max-width: 768px) {
    .changepasswordcard {
        width: 50%;
    }

    .progressbardiv {
        padding: 50px 25% 0 25% !important;
    }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
    .changepasswordcard {
        width: 40%;
    }

    .progressbardiv {
        padding: 50px 30% 0 30% !important;
    }
}