#logincontent {
    background: #011e42;
    font-family: "AileronRegular", "OpenSansRegular";
    height: 100vh;
}

.logintop {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: row;
    padding-top: 3%;
}

.roiquantlogo {
    width: 180px;
}

.progressbardiv {
    padding: 50px 34% 0 34%;
}

.progressbardiv>div {
    height: 0.5rem;
    background-color: white;
    border-radius: .35rem 0.35rem 0 0;
}

#loginform {
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: row;
    background: #011e42;
}

.logincard {
    display: block;
    position: relative;
    margin-bottom: 30px;
    background: #fff;
    border-radius: 0 0 .35rem 0.35rem !important;
    box-shadow: 0 23px 20px -20px rgb(0 0 0 / 10%), 0 0 15px rgb(0 0 0 / 6%);
    padding: 40px;
    width: 32%;
    /* margin-top: -0.25rem; */
    border-top: transparent !important;
}

.loginheader {
    margin: 0 0 0 0;
    text-align: center;
    font-size: 28px;
}

.createaccalerttop {
    display: block;
    justify-content: center;
    align-content: center;
    margin: auto;
    width: 32%;
    text-align: justify;
}

.createaccnote {
    text-align: center;
    margin-bottom: 40px;
    font-size: 16px;
}

.createaccnote a {
    color: #039be5 !important;
    text-decoration: underline;
}

.inputbox {
    background-color: white !important;
    border-top: transparent;
    border-right: transparent;
    border-left: transparent;
    padding: 20px !important;
    font-size: 16px !important;
    width: 100%;
}

.loginbtn {
    background-color: #0065ff !important;
    color: white !important;
    border: 1px solid #0065ff !important;
    padding: 6px 15px !important;
    margin: 15px 0 !important;
    font-weight: bold !important;
    font-family: "AileronRegular", "OpenSansRegular" !important;
    font-size: 16px !important;
    border-radius: 5px !important;
}

.forgotpw {
    padding-top: 5% !important;
}

.forgotpw a {
    color: #039be5 !important;
    text-decoration: underline;
}

@media only screen and (max-width: 425px) {
    .logincard {
        width: 80%;
    }

    .progressbardiv {
        padding: 50px 10% 0 10% !important;
    }
}

@media only screen and (min-width: 426px) and (max-width: 576px) {
    .logincard {
        width: 70%;
    }

    .progressbardiv {
        padding: 50px 15% 0 15% !important;
    }
}

@media only screen and (min-width: 577px) and (max-width: 768px) {
    .logincard {
        width: 50%;
    }

    .progressbardiv {
        padding: 50px 25% 0 25% !important;
    }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
    .logincard {
        width: 40%;
    }

    .progressbardiv {
        padding: 50px 30% 0 30% !important;
    }
}