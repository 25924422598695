#competitivelandscape {
    width: 100%;
    background-color: #eff8ff;
    min-height: 120vh;
    position: relative;
    font-family: "AileronRegular", "OpenSansRegular";
}

.competitivelandscapeheaderdiv {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.competitivelandscapetitlediv {
    width: 65%;
    padding: 50px;
}

.competitivelandscapestatsdiv {
    font-family: "AileronRegular", "OpenSansRegular";
    width: 50%;
    padding: 50px;
}

.competitivelandscapetext {
    font-family: "AileronRegular", "OpenSansRegular";
    text-align: left;
    font-size: 28px;
    font-weight: bold;
}

.competitivelandscapesector {
    font-size: 24px;
}

.competitivelandscapeindustrybar {
    background-color: #E6E6E6;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    width: 100% !important;
}

.competitivelandscaperightbar {
    font-weight: bold;
    width: 65%;
    margin-top: 5px;
}

.competitivelandscapeleftbar {
    text-align: right;
    width: 35%;
}

.competitivelandscapebutton {
    background-color: #CCCCCC !important;
    color: black !important;
    border: 1px solid #CCCCCC !important;
    padding: 6px 15px !important;
    margin: 0 15px 0 0 !important;
    font-weight: bold !important;
    font-family: "AileronRegular", "OpenSansRegular" !important;
    font-size: 16px !important;
    border-radius: 5px !important;
}

.competitivelandscapeellipsisicon {
    margin-top: -2px !important;
    margin-right: 10px;
}

.competitivelandscapelogodiv {
    padding: 24px 24px 0;
    margin-left: .25rem !important;
    margin-right: 0 !important;
    display: flex;
    flex-wrap: wrap;
}

.competitivelandscapelogorow {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 100%;
    max-width: 100%;
}

.competitivelandscapelogo {
    width: 100%;
}

.competitivelandscapename {
    font-family: "AileronRegular", "OpenSansRegular";
    font-size: 12px;
    margin: 0.5rem 0 1rem 0;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.competitive-landscape-card-header {
    padding: 10px;
    display: flex;
    flex-direction: row;
    width: 100%;
    overflow: hidden;
    text-overflow: clip;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.competitive-landscape-card-title {
    color: #1f5f8b;
    font-size: 16px;
    font-weight: bold;
    width: 80%;
}

.competitive-landscape-card-ellipsis {
    width: 20%;
    text-align: right;
}

.competitivelandscapecatscore {
    padding: 0 5px;
    color: darkred;
    background-color: pink;
    font-size: 16px;
    font-weight: bold;
}

.competitivelandscapecategorystats {
    width: 100%;
    padding: 10px;
}

.competitivelandscapecategorystats td {
    text-align: center;
}

/* table css */

.competitivelandscapetablecategoryheader {
    font-size: 12px;
    width: 16%;
    vertical-align: top;
}

.competitivelandscapetablecategorydata {
    font-size: 14px;
    font-weight: bold;
    vertical-align: top;
}

.competitivelandscapeline {
    border: 0;
    clear: both;
    display: block;
    width: 100%;
    background-color: #808080;
    height: 1px;
}

.competitivelandscapeindex {
    font-size: 12px;
    padding: 15px 10px 10px 10px;
    width: 100%;
}

.competitivelandscapeformheader {
    border-bottom: none !important;
    padding: 1rem 1.5rem 2rem 1.5rem !important;
}

.competitivelandscapeformheader>p {
    font-family: "AileronRegular", "OpenSansRegular";
    font-size: 20px;
    font-weight: bold;
    padding: 10px 0 20px 0;
}

.competitivelandscapeformbody {
    padding: 0 1.5rem 2.8rem 1.5rem !important;
}

.competitivelandscapeplotbutton {
    background-color: royalblue !important;
    color: white;
    border: 1px solid royalblue !important;
    padding: 6px 15px !important;
    margin: 1rem 0 !important;
    font-weight: bold !important;
    font-family: "AileronRegular", "OpenSansRegular" !important;
    font-size: 14px !important;
    border-radius: 5px !important;
}

.searchBox {
    width: 100% !important;
    margin-top: 0px !important;
    /* font-size: 10px !important; */
}

.search-wrapper {
    height: auto !important;
}

.competitivelandscapeprompt {
    padding-left: 5%;
}

.competitivelandscapeprogressbar>div {
    height: 0.3rem;
    background-color: #eff8ff;
    border-radius: 0 !important;
}

@media (max-width: 576px) {
    .competitivelandscaperightbar {
        width: 50%;
    }

    .competitivelandscapeleftbar {
        width: 50%;
    }
}

@media (max-width: 768px) {
    .competitivelandscapeindustrybar {
        flex-direction: column;
    }

    .competitivelandscaperightbar,
    .competitivelandscapeleftbar {
        width: 100%;
    }

    .competitivelandscapeheaderdiv {
        flex-direction: column;
    }

    .competitivelandscapetitlediv {
        width: 100%;
        padding: 50px 50px 25px 50px;
    }

    .competitivelandscapestatsdiv {
        width: 100%;
        padding: 25px 50px 50px 50px;
    }
}

@media (max-width: 1200px) {
    .competitivelandscapeheader {
        flex-direction: column;
    }

    .competitivelandscapetitlediv {
        width: 100%;
    }

    .competitivelandscapetitlediv {
        padding: 50px 50px 10px 50px;
    }
}

.competitive-landscape-cards {
    padding: 50px 0 0 0;
}

.competitivecardcompanycard {
    padding: 24px 24px 0 24px;
}

@media only screen and (max-width: 640px) {
    .competitivecardcompanycard {
        padding: 24px 0 0 0;
    }
}

.competitivecardrow {
    display: flex;
    flex-wrap: wrap;
}

.competitivelogorow {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    width: 100%;
    min-height: 430px;
    place-content: start;
}

.competitivecardml1 {
    margin-left: .25rem !important;
}

.competitivecardmr0 {
    margin-right: 0 !important;
}

/* col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 */
.competitive-landscape-cards-row {
    position: relative;
    width: 100%;
    padding-left: 15px;
}

/* col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 */
.competitivelogocardrow {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

/* min-width */

@media only screen and (min-width: 576px) and (max-width: 1199px) {
    .competitive-landscape-cards-row {
        flex: 0 0 50%;
        max-width: 50%;
    }
}

@media only screen and (min-width: 1200px) {
    .competitive-landscape-cards-row {
        flex: 0 0 33%;
        max-width: 33%;
    }
}

/* max-width */

@media only screen and (max-width: 575px) {
    .competitive-landscape-cards-row {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

@media only screen and (max-width: 640px) {
    .competitivecardcompanycard {
        padding: 24px 0 0 0;
    }
}

.competitivecardcard {
    margin: 0 0 30px 0;
}

.competitivecardf {
    min-height: 550px;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.competitivelogof {
    min-height: 75px;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.competitivecardimgthumbnail {
    padding: 0.25rem;
}

/* .fxlayout-column-fxlayoutalign-spacebetweencenter */
.competitivecardfxlayout1 {
    flex-direction: column;
    box-sizing: border-box;
    display: flex;
    /* place-content: center space-between; */
    align-items: center;
}

.competitivelogofxlayout1 {
    flex-direction: column;
    box-sizing: border-box;
    display: flex;
    place-content: center;
    align-items: center;
}

@media (max-width: 1400px) {
    .competitive-landscape-card-header {
        min-height: 70px;
    }

    .competitivelogocardrow {
        flex: 0 0 33%;
        max-width: 33%;
    }

    .competitivelogorow {
        min-height: 770px;
    }
}

@media (min-width: 1401px) {
    .competitivelogocardrow {
        flex: 0 0 20%;
        max-width: 20%;
    }
}