#funding {
    width: 100%;
    background-color: #eff8ff;
    min-height: 100%;
    position: relative;
    font-family: "AileronRegular", "OpenSansRegular";
}

.fundingheaderdiv {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.fundingtitlediv {
    width: 80%;
    padding: 50px;
}

.fundingstatsdiv {
    width: 20%;
    padding: 50px;
}

@media (max-width: 768px) {
    .fundingheaderdiv {
        flex-direction: column;
    }

    .fundingtitlediv {
        width: 100%;
        padding: 50px 50px 25px 50px;
    }

    .fundingstatsdiv {
        width: 100%;
        padding: 25px 50px 50px 50px;
    }
}

.fundinglink {
    color: #039be5 !important;
}

.fundinglink:hover {
    color: #039be5 !important;
    text-decoration: underline;
}

.fundingtext {
    font-family: "AileronRegular", "OpenSansRegular";
    text-align: left;
    font-size: 28px;
    font-weight: bold;
}

.fundingsearchdiv {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.fundingsearchbox {
    width: 30%;
    padding: 20px 10px 20px 50px;
}

.fundingsearchbuttons {
    width: 30%;
    padding: 20px 50px 20px 10px;
}

.fundingresetbutton {
    padding: 5px 25px;
    background: transparent;
    outline: none !important;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
    width: 90px;
    height: 42px;
    line-height: 40px;
    padding: 0;
    border: none;
    margin-right: 10px;
}

.fundingresetbutton span {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    font-family: "AileronRegular", "OpenSansRegular";
    font-size: 14px;
    font-weight: bold;
}

.fundingresetbutton:before,
.fundingresetbutton:after {
    position: absolute;
    content: "";
    height: 0%;
    width: 2px;
    background: gainsboro;
}

.fundingresetbutton:before {
    right: 0;
    top: 0;
    transition: all 0.1s ease;
}

.fundingresetbutton:after {
    left: 0;
    bottom: 0;
    transition: all 0.1s 0.2s ease;
}

.fundingresetbutton:hover:before {
    transition: all 0.1s 0.2s ease;
    height: 100%;
}

.fundingresetbutton:hover:after {
    transition: all 0.1s ease;
    height: 100%;
}

.fundingresetbutton span:before,
.fundingresetbutton span:after {
    position: absolute;
    content: "";
    background: gainsboro;
}

.fundingresetbutton span:before {
    left: 0;
    top: 0;
    width: 0%;
    height: 2px;
    transition: all 0.1s 0.1s ease;
}

.fundingresetbutton span:after {
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
}

.fundingresetbutton span:hover:before {
    width: 100%;
}

.fundingsearchbutton {
    height: 42px;
    color: black !important;
    padding: 5px 25px !important;
    font-family: "AileronRegular", "OpenSansRegular";
    font-size: 14px !important;
    font-weight: bold !important;
    background: transparent !important;
    outline: none !important;
    cursor: pointer !important;
    transition: all 0.3s ease !important;
    position: relative !important;
    display: inline-block !important;
    border: 2px solid gainsboro !important;
    border-radius: 0 !important;
    z-index: 1 !important;
    margin-left: 10px !important;
}

.fundingsearchbutton:after {
    position: absolute;
    content: "";
    width: 0;
    height: 100%;
    top: 0;
    left: 0;
    direction: rtl;
    z-index: -1;
    background: gainsboro;
    transition: all 0.3s ease;
}

.fundingsearchbutton:hover:after {
    left: auto;
    right: 0;
    width: 100%;
}

.fundingsearchbutton:active {
    top: 2px;
}

.fundingdiv {
    overflow-x: auto;
    overflow-y: auto;
    white-space: nowrap;
    font-family: "AileronRegular", "OpenSansRegular" !important;
    font-size: 16px;
    min-width: 100%;
    height: 100vh;
}

.fundingtable {
    background-color: white;
}

.fundingtable table {
    table-layout: fixed;
    width: 100%;
}

.fundingtable tr:not(:first-child) {
    height: 100px;
}

.fundingtable thead th:nth-child(3) {
    position: sticky;
    position: -webkit-sticky;
    left: 0;
    z-index: 2;
}

.fundingtable thead th:nth-child(4) {
    position: sticky;
    position: -webkit-sticky;
    left: 10vw;
    z-index: 2;
}

.fundingtable tbody td:nth-child(3) {
    position: sticky;
    position: -webkit-sticky;
    left: 0;
    z-index: 1;
    background-color: white;
}

.fundingtable tbody td:nth-child(4) {
    position: sticky;
    position: -webkit-sticky;
    left: 10vw;
    z-index: 1;
    background-color: white;
}

.fundingtable th {
    text-align: center;
    white-space: nowrap;
    padding: 8px;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    background: #e3e7f1;
    border-left: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6;
}

.fundingtable td {
    border: 1px solid #dee2e6;
}

.fundingtable td:hover {
    .expand-button {
        color: black !important;
    }
}

.expand-button {
    background-color: transparent !important;
    color: transparent !important;
    border: none !important;
    border-radius: 5px !important;
    float: right;
    padding: 0 4px !important;
}

.expand-button:hover {
    background-color: #edeff2 !important;
}

.fundingtable td:not(:first-child) {
    padding: 15px;
}

.fundingfilters {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    border-top: 2px solid #dee2e6;
    background: aliceblue;
    width: 100%;
    display: flex;
    flex-direction: row;
}

.filterrownumber {
    width: 30%;
    text-align: left;
}

.filterrownumber>p {
    margin: 1rem;
}

.filterpagination {
    width: 40%;
    text-align: center;
}

.filterpage,
.filterpage:hover {
    color: #039be5 !important;
    border: none !important;
    background: none !important;
    padding: 0 0.75rem !important;
    margin: 1rem 0 !important;
}

.filterrowshow {
    width: 30%;
    text-align: right;
    display: flex;
    flex-direction: row;
}

.filterrowshow>p {
    width: 50%;
    margin: 1rem 0;
}

.filterrowselect {
    width: 50% !important;
    margin: 0.6rem 0.75rem 0.4rem 0.75rem;
}

.investorlogo {
    width: 100px;
    height: auto;
    padding: 15px;
}

.detailnote {
    padding-bottom: 20px;
}

.detailbodytitle {
    font-size: 14px;
    color: slategrey;
    margin-bottom: 0 !important;
}

.detailemptydata {
    text-align: center;
}

/* 
.detailxsdata {
    white-space: normal;
    width: 2vw;
    margin-bottom: 0;
    text-align: center;
}

.detailshortdata {
    white-space: normal;
    width: 8vw;
    margin-bottom: 0;
    text-align: center;
}

.detailmediumdata {
    white-space: normal;
    width: 10vw;
    margin-bottom: 0;
}

.detailmldata {
    white-space: normal;
    width: 15vw;
    margin-bottom: 0;
}

.detaillongdata {
    white-space: normal;
    width: 23vw;
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.detailextralongdata {
    white-space: normal;
    width: 30vw;
    margin-bottom: 0;
} */

.funding-cut-data {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
}

.detailbodytitle {
    font-size: 14px;
    font-weight: bold;
    color: slategrey;
    margin-bottom: 0 !important;
}

.detailbodydata {
    font-size: 16px;
}

.detailproductlist {
    padding: 0 0 0 30px;
}

.detailproductlist>li:hover {
    background: none !important;
}

.progressbardivtable>div {
    height: 0.3rem;
    background-color: #eff8ff;
    border-radius: 0 !important;
}

@media (max-width: 1200px) {
    .fundingsearchbox {
        width: 50%;
    }

    .fundingsearchbuttons {
        width: 50%;
    }
}

@media (max-width: 576px) {
    /* .detailxsdata {
        width: 6vw;
    }

    .detailshortdata {
        width: 24vw;
    }

    .detailmediumdata {
        width: 30vw;
    }

    .detailmldata {
        width: 45vw;
    }

    .detaillongdata {
        width: 69vw;
    }

    .detailextralongdata {
        width: 90vw;
    } */

    .fundingfilters {
        flex-direction: column;
        font-size: 14px !important;
    }

    .filterrownumber,
    .filterpagination,
    .filterrowshow {
        width: 100%;
        text-align: center;
    }

    .filterrownumber>p {
        margin: 0.3rem 0 !important;
    }

    .filterpage,
    .filterpage:hover {
        font-size: 14px !important;
        margin: 0.3rem 0 !important
    }

    .filterrowshow>p,
    .filterrowselect {
        margin: 0.3rem 0 !important;
    }
}

@media (max-width: 425px) {
    .fundingsearchdiv {
        flex-direction: column;
    }

    .fundingsearchbox {
        width: 100%;
        padding: 25px 50px 0 50px;
    }

    .fundingsearchbuttons {
        width: 100%;
        padding: 0 50px 50px 50px;
    }
}

.funding-header {
    height: 5px !important;
    border: none !important;
    padding: 1rem 1rem 2.4rem 1rem !important;
    /* margin-bottom: 1.0rem !important; */
}

.close {
    font-size: 1.3rem !important;
    border: none !important;
}

/* .funding-header .close {
    font-size: 1.3rem !important;
    border: none !important;
} */

.funding-body {
    max-height: 90vh !important;
    overflow-y: auto !important;
    overflow-x: hidden;
}

/* max-width */

@media only screen and (max-width: 575px) {
    .funding-body {
        height: 20vh !important;
        max-height: 20vh !important;
    }
}

@media only screen and (max-width: 600px) {
    .funding-body {
        height: 20vh !important;
        max-height: 20vh !important;
    }
}

@media only screen and (max-width: 475px) {
    .funding-body {
        height: 20vh !important;
        max-height: 20vh !important;
    }
}