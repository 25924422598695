#portfolioanalysis {
    width: 100%;
    background-color: #eff8ff;
    min-height: 120vh;
    position: relative;
    font-family: "AileronRegular", "OpenSansRegular";
}

#title-div {
    padding: 2% 0;
    /* font-family: "AileronRegular", "OpenSansRegular"; */
}

#title-text {
    font-family: "AileronRegular", "OpenSansRegular";
    text-align: left;
    font-size: 28px;
    font-weight: bold;
}

#information-icon {
    height: 0.75em;
    width: 0.75em;
    margin-left: 5px;
}

.tab-body {
    margin-top: 40px;
    padding-bottom: 40px;
}

.card-header {
    font-family: "AileronRegular", "OpenSansRegular";
    font-size: 18px;
    display: flex;
    background-color: white !important;
    border-color: #cdcdcd !important;
}

.card-text {
    color: #29323a !important;
}

.card-body {
    background-color: white !important;
    border-color: #cdcdcd !important;
}

.arrow-icon {
    height: 15px !important;
    margin-top: -3px !important;
    padding-right: 5px !important;
}

.modal-div {
    font-family: "AileronRegular", "OpenSansRegular";
    font-size: 16px;
}

.portfolio-modal-body {
    margin-top: -30px;
    padding: 1rem 1.5rem 3rem 1.5rem !important;
}

.modal-compare {
    background-color: royalblue !important;
    color: white !important;
    border: 1px solid royalblue !important;
    padding: 6px 15px !important;
    margin: 1rem 0 0 0 !important;
    font-weight: bold !important;
    font-family: "AileronRegular", "OpenSansRegular" !important;
    font-size: 14px !important;
    border-radius: 5px !important;
}

.modal-delete {
    background-color: red !important;
    color: white !important;
    border: 1px solid red !important;
    padding: 6px 15px !important;
    margin: 1rem 0 0 0 !important;
    font-weight: bold !important;
    font-family: "AileronRegular", "OpenSansRegular" !important;
    font-size: 14px !important;
    border-radius: 5px !important;
}

.edit-button {
    background-color: transparent !important;
    color: black !important;
    border: none !important;
    border-radius: 5px !important;
}

.delete-button {
    background-color: transparent !important;
    color: red !important;
    border: none !important;
    border-radius: 5px !important;
}

.download-button {
    background-color: transparent !important;
    color: #039be5 !important;
    border: none !important;
    border-radius: 5px !important;
}

.edit-button:hover,
.delete-button:hover,
.download-button:hover {
    background-color: #edeff2 !important;
}

.modal-list {
    padding-left: 20px !important;
}

.modal-list>li:hover {
    background-color: transparent;
}

.modal-product {
    background-color: #edeff2 !important;
    color: grey !important;
    border: 1px solid #edeff2 !important;
    padding: 6px 15px !important;
    margin: 1rem 0 0 0 !important;
    font-weight: bold !important;
    font-family: "AileronRegular", "OpenSansRegular" !important;
    font-size: 14px !important;
    border-radius: 5px !important;
}

@media only screen and (max-width: 425px) {
    #title-text {
        font-size: 20px !important;
    }

    .card-header {
        font-size: 12px !important;
    }
}