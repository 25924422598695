#postmortemanalysis {
    width: 100%;	
	background-color: #eff8ff;
	min-height: 120vh;
    position: relative;
    font-family: "AileronRegular", "OpenSansRegular";
}

.postmortemanalysisheaderdiv {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.postmortemanalysistitlediv {
    width: 70%;
    padding: 50px 50px 50px 0;
}

.postmortemanalysisstatsdiv {
    font-family: "AileronRegular", "OpenSansRegular";
    width: 30%;
    padding: 50px 0 50px 50px;
}

@media (max-width: 768px) {
    .postmortemanalysisheaderdiv {
        flex-direction: column;
    }

    .postmortemanalysistitlediv {
        width: 100%;
        padding: 50px 0 25px 0;
    }
    
    .postmortemanalysisstatsdiv {
        width: 100%;
        padding: 25px 0 50px 0;
    }
}

.postmortemanalysistext {
    font-family: "AileronRegular", "OpenSansRegular";
    text-align: left;
    font-size: 28px;
    font-weight: bold;
}

#da-charts {
    width: 1100px !important;
    height: 1100px !important;
}

@media only screen and (max-width: 425px) {
    #da-charts {
        width: 450px !important;
        height: 450px !important;
    }
}

@media only screen and (min-width: 426px) and (max-width: 576px) {
    #da-charts {
        width: 450px !important;
        height: 450px !important;
    }
}

@media only screen and (min-width: 577px) and (max-width: 768px) {
    #da-charts {
        width: 450px !important;
        height: 450px !important;
    }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
    #da-charts {
        width: 600px !important;
        height: 600px !important;
    }
}

@media only screen and (min-width: 1025px) and (max-width: 1440px) {
    #da-charts {
        width: 850px !important;
        height: 850px !important;
    }
}

@media only screen and (min-width: 1441px) {
    #da-charts {
        width: 1200px !important;
        height: 1200px !important;
    }
}