.alertBannerSticky {
    position: fixed !important;
    top: 97px;
    z-index: 3;
}

.alertBannerComponent {
    width: 100%;
    background-color: #fde780;
    border-radius: 0 !important;
    margin-bottom: 0 !important;
    padding: 12px 16px !important;
    min-height: 48px !important;
}

@media only screen and (max-width: 425px) {
    .alertBannerComponent {
        padding: 12px 12% !important;
    }
}

@media only screen and (max-width: 1440px) and (min-width: 426px) {
    .alertBannerComponent {
        padding: 12px 4% !important;
    }
}

.alertBannerText {
    font-size: 16px;
    text-align: center;
    margin-bottom: 0;
    font-family: "AileronRegular";
}

.customButton {
    padding: 10px 25px;
    font-family: "AileronRegular", "OpenSansRegular";
    color: black;
    background: transparent;
    outline: none !important;
    cursor: default !important;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
    font-size: 14px;
    margin-right: 10px;
}

.customButtonStyle {
    width: 150px;
    height: 40px;
    line-height: 42px;
    padding: 0;
    border: none;
}

.customButtonStyle>span {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
}

.customButtonStyle:before,
.customButtonStyle:after {
    position: absolute;
    content: "";
    right: 0;
    top: 0;
    background: black;
    transition: all 0.3s ease;
}

.customButtonStyle:before {
    height: 50%;
    width: 2px;
}

.customButtonStyle:after {
    width: 20%;
    height: 2px;
}

.customButtonStyle>span:before,
.customButtonStyle>span:after {
    position: absolute;
    content: "";
    left: 0;
    bottom: 0;
    background: black;
    transition: all 0.3s ease;
}

.customButtonStyle>span:before {
    width: 2px;
    height: 50%;
}

.customButtonStyle>span:after {
    width: 20%;
    height: 2px;
}

/* fixes alert banner close button */
#alertBannerComponent .close {
    font-size: 1rem !important;
    height: 100% !important;
}