@font-face {
  font-family: 'AileronRegular';
  src: url("./assets/fonts/aileron/Aileron-Regular.eot");
  src: url("./assets/fonts/aileron/Aileron-Regular.eot?#iefix") format("embedded-opentype"), url("./assets/fonts/aileron/Aileron-Regular.woff2") format("woff2"),
    url("./assets/fonts/aileron/Aileron-Regular.woff") format("woff"), url("./assets/fonts/aileron/Aileron-Regular.ttf") format("truetype"),
    url("./assets/fonts/aileron/Aileron-Regular.svg#Aileron-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AileronItalic';
  src: url('./assets/fonts/aileron/Aileron-Italic.otf');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'AileronThinItalic';
  src: url("./assets/fonts/aileron/Aileron-ThinItalic.eot");
  src: url("./assets/fonts/aileron/Aileron-ThinItalic.eot?#iefix") format("embedded-opentype"), url("./assets/fonts/aileron/Aileron-ThinItalic.woff2") format("woff2"),
    url("./assets/fonts/aileron/Aileron-ThinItalic.woff") format("woff"), url("./assets/fonts/aileron/Aileron-ThinItalic.ttf") format("truetype"),
    url("./assets/fonts/aileron/Aileron-ThinItalic.svg#Aileron-ThinItalic") format("svg");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'OpenSansRegular';
  src: url('./assets/fonts/Open-Sans/OpenSans-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSansItalic';
  src: url('./assets/fonts/Open-Sans/OpenSans-Italic.ttf');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'OpenSansLightItalic';
  src: url('./assets/fonts/Open-Sans/OpenSans-LightItalic.ttf');
  font-weight: normal;
  font-style: italic;
}

body {
  margin: 0;
  font-family: 'AileronRegular', 'AileronItalic', 'AileronThinItalic', 'OpenSansRegular', 'OpenSansItalic', 'OpenSansLightItalic', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: aliceblue !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

* {
  box-sizing: border-box;
}

@media print {
  body {
    display: none;
  }
}