#thankyoucontainer {
    background-color: aliceblue;
    width: 100%;
    min-height: 120vh;
    margin: 0px;
    font-family: "AileronRegular", "OpenSansRegular";
}

.thankyouprogressbardiv {
    padding: 50px 34% 0 34%;
}

.thankyouprogressbardiv>div {
    height: 0.5rem;
    background-color: white;
    border-radius: .35rem 0.35rem 0 0;
}

#thankyouloginform {
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: row;
    padding-top: 5%;
}

.thankyoulogincard {
    display: block;
    position: relative;
    margin-bottom: 30px;
    background: #fff;
    border-radius: 0 0 .35rem 0.35rem !important;
    box-shadow: 0 23px 20px -20px rgb(0 0 0 / 10%), 0 0 15px rgb(0 0 0 / 6%);
    padding: 40px;
    width: 32%;
    margin-top: -0.25rem;
    border-color: transparent !important;
}

.thankyouloginheader {
    margin: 0 0 0 0;
    text-align: center;
    font-size: 28px;
}

.thankyoucreateaccnote {
    text-align: center;
    margin-bottom: 40px;
    font-size: 16px;
}

.thankyoucreateaccnote a, .thankyoutext>p>a {
    color: #039be5 !important;
    text-decoration: underline;
}

.thankyouinputbox {
    background-color: rgba(0, 0, 0, .04);
    border-top: transparent;
    border-right: transparent;
    border-left: transparent;
    padding: 20px !important;
    font-size: 16px !important;
    width: 100%;
}

.thankyouloginbtn {
    background-color: #0065ff !important;
    color: white !important;
    border: 1px solid #0065ff !important;
    padding: 6px 15px !important;
    margin: 15px 0 !important;
    font-weight: bold !important;
    font-family: "AileronRegular", "OpenSansRegular" !important;
    font-size: 16px !important;
    border-radius: 5px !important;
}

.thankyoucard {
    display: block;
    position: relative;
    background: #fff;
    border-radius: 0.35rem;
    box-shadow: 0 23px 20px -20px rgb(0 0 0 / 10%), 0 0 15px rgb(0 0 0 / 6%);
    padding: 40px;
    width: 70%;
    border-top: transparent;
}

.thankyouheader {
    font-size: 30px;
    font-weight: bold;
}

.thankyouheader2 {
    font-size: 24px;
    font-weight: bold;
}

.thankyoutext {
    margin: 0 12%;
    text-align: left;
    padding-top: 40px;
    font-size: 18px;
}

.thankyoubutton {
    margin-top: 50px;
}

.thankyoubutton>a:hover {
    text-decoration: none;
}

.thankyoudashboardbutton {
    background-color: royalblue !important;
    color: white;
    border: 1px solid royalblue !important;
    padding: 6px 15px !important;
    margin: 0 !important;
    font-weight: bold !important;
    font-family: "AileronRegular", "OpenSansRegular" !important;
    font-size: 14px !important;
    border-radius: 5px !important;
}

.thankyouconfirmbutton {
    background-color: transparent !important;
    color: #039be5;
    border: 1px solid transparent !important;
    padding: 6px 15px !important;
    margin: 0 !important;
    /* font-weight: bold !important; */
    font-family: "AileronRegular", "OpenSansRegular" !important;
    font-size: 14px !important;
    border-radius: 5px !important;
}

.thankyouconfirmbutton:hover {
    text-decoration: underline !important;
}

#tyChecksField{
    padding-left: 5px;
    padding-right: 55px;
    padding-bottom: 10px;
    font-size: 10px;
    color: gray;
    display: block;
}

@media only screen and (max-width: 425px) {
    .thankyoulogincard {
        width: 80%;
    }

    .thankyouprogressbardiv {
        padding: 50px 10% 0 10% !important;
    }
}

@media only screen and (min-width: 426px) and (max-width: 576px) {
    .thankyoulogincard {
        width: 70%;
    }

    .thankyouprogressbardiv {
        padding: 50px 15% 0 15% !important;
    }
}

@media only screen and (min-width: 577px) and (max-width: 768px) {
    .thankyoulogincard {
        width: 50%;
    }

    .thankyouprogressbardiv {
        padding: 50px 25% 0 25% !important;
    }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
    .thankyoulogincard {
        width: 40%;
    }

    .thankyouprogressbardiv {
        padding: 50px 30% 0 30% !important;
    }
}

@media (max-width: 576px) {
    .thankyouheader {
        padding: 0 5%;
    }

    .thankyoutext {
        margin: 0 10%;
    }
}