button:focus {
    outline: none !important;
    outline-offset: none !important;
}

.glintanimation {
    position: relative;
}

.glintanimation:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.4);
    -webkit-transition: none;
    -moz-transition: none;
    transition: none;
}

.glintanimation:hover:after {
    width: 120%;
    background-color: rgba(255, 255, 255, 0);
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}

#navbarcomponent a {
    color: grey !important;
}

.navbartop,
.navbarbottom {
    width: 100%;
    border-bottom: solid 1px lightgrey;
    padding: 0.1rem 1rem !important;
}

.navbartopsticky {
    width: 100%;
    border-bottom: solid 1px lightgrey;
    padding: 0.1rem 1rem !important;
    position: fixed !important;
    top: 0px !important;
    z-index: 4 !important;
}

.navbarbottomsticky {
    width: 100%;
    border-bottom: solid 1px lightgrey;
    padding: 0.1rem 1rem !important;
    position: fixed !important;
    top: 44px !important;
    z-index: 3 !important;
}

.navbartooltip {
    font-family: "AileronRegular", "OpenSansRegular";
    text-transform: none;
    max-width: 500px;
}

.navbardashboard,
.navbarlogin {
    background-color: transparent !important;
    color: grey;
    border: none !important;
    padding: 6px 15px !important;
    margin: 0 !important;
    font-weight: bold !important;
    font-family: "AileronRegular", "OpenSansRegular" !important;
    font-size: 14px !important;
    border-radius: 5px !important;
}

.navbardashboard:hover,
.navbarlogin:hover {
    background-color: #edeff2 !important;
}

.navbarslogan {
    flex: 40;
    text-align: left;
    font-size: 22px;
    font-family: "AileronThinItalic", "OpenSansLightItalic";
    padding: 0px;
    letter-spacing: 1px;
    color: darkslategrey;
}

.navbarslogan>span {
    margin-left: 16px;
}

#navbaractive:hover {
    color: grey;
    background-color: #edeff2;
    height: 100%;
    text-decoration: underline;
}

#navbarinactive:hover {
    text-decoration: none;
}

.navbaricon {
    height: 1.4em;
    width: 1.4em;
    color: slategrey;
}

.navbarlogodiv {
    text-align: center;
    margin-right: 8%;
    flex: 40;
}

.navbarlogo {
    width: 140px;
    /* height: 38px; */
}

.navbarlogo2 {
    width: 30px;
    height: 30px;
}

.navbarideachecker {
    background-color: #0f9d58 !important;
    color: white;
    border: none !important;
    padding: 6px 15px !important;
    margin: 0 !important;
    font-weight: bold !important;
    font-family: "AileronRegular", "OpenSansRegular" !important;
    font-size: 14px !important;
    border-radius: 5px !important;
}

.navbaraddnew {
    background-color: #0E49B5 !important;
    color: white !important;
    border: none !important;
    padding: 6px 15px !important;
    margin: 0 !important;
    font-weight: bold !important;
    font-family: "AileronRegular", "OpenSansRegular" !important;
    font-size: 14px !important;
    border-radius: 5px !important;
}

.navbardropdownitem {
    font-size: 16px !important;
    font-family: "AileronRegular", "OpenSansRegular";
    color: #16181b;
}

.navbardropdownitem:hover {
    color: #16181b;
}

.navbardropdown .dropdown-toggle:after {
    content: none !important;
}

.navbardropdown .dropdown-menu {
    top: 125% !important;
    width: max-content !important;
}

li.dropdown-submenu:hover>ul.dropdown-menu {
    display: block;
}

.dropdown-submenu {
    display: block;
    width: 100%;
    padding: .25rem 1.5rem;
    clear: both;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}

.dropdown-submenu>.dropdown-menu {
    top: 0;
    right: 100%;
    margin-top: -30px !important;
    position: absolute;
    min-width: 10rem;
    padding: .5rem 0;
    margin: .125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: .25rem;
}

@media only screen and (max-width: 410px) {

    .navbarlogo,
    .navbarlogo2 {
        display: none;
    }
}

@media only screen and (max-width: 425px) {
    .navbaricon {
        height: 1em;
        width: 1em;
    }

    .navbarbottomsticky {
        top: 43px
    }
}

@media only screen and (min-width: 411px) and (max-width: 425px) {
    .navbarlogodiv {
        text-align: left;
        margin-right: 0;
    }
}

@media only screen and (max-width: 576px) {
    .navbartooltip {
        font-family: "AileronRegular", "OpenSansRegular";
        text-transform: none;
        max-width: 300px;
    }

    .navbarlogo {
        display: none;
    }

    .navbarbottomsticky {
        top: 44.5px
    }
}

@media only screen and (min-width: 426px) and (max-width: 576px) {
    .navbarlogodiv {
        text-align: left;
        margin-left: 14%;
        margin-right: 0;
    }
}

@media only screen and (min-width: 577px) {
    .navbarlogo2 {
        display: none;
    }
}

@media only screen and (min-width: 577px) and (max-width: 768px) {
    .navbarlogodiv {
        margin-right: 3%;
    }

    .navbarbottomsticky {
        top: 45px
    }
}

@media only screen and (max-width: 1024px) {
    .navbarslogan {
        font-size: 0px !important;
    }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
    .navbarlogodiv {
        margin-right: 8%;
    }

    .navbarbottomsticky {
        top: 45px
    }
}

@media only screen and (min-width: 1025px) and (max-width: 1440px) {
    .navbarslogan>span {
        margin-left: 0%;
    }

    .navbarlogodiv {
        margin-right: 7%;
    }

    .navbarbottomsticky {
        top: 45px
    }
}

#sidebarcont {
    font-family: "AileronRegular", "OpenSansRegular" !important;
    font-size: 16px !important;
    width: 284px;
    height: 100%;
    background-color: #011e42;
    position: fixed;
    right: inherit;
    z-index: 1100;
    width: 300px;
    height: 100%;
    transition: all 0.5s ease 0s;
    overflow-y: scroll;
}

.sidebarclose {
    text-align: right;
}

.sidebarclose>button {
    color: white;
}

.sidebarbutton {
    border: none;
    background-color: transparent;
}

.sidebarhead {
    padding-left: 1rem;
    padding-bottom: 1rem;
}

.sidebarback {
    color: white;
    padding-bottom: 8px;
}

.sidebarback>a,
.sidebartab,
.sidebardashboard {
    color: white;
    text-decoration: none;
}

.sidebarback>a:hover,
.sidebartab:hover,
.sidebardashboard:hover {
    color: white !important;
    text-decoration: underline;
    cursor: pointer;
}

.sidebarbackicon {
    height: 1.5em;
    width: 1.5em;
    margin-right: 12px;
    margin-left: 18px;
}

.sidebarlogo {
    width: 100px;
}

.sidebarheader,
.sidebarheader2,
.sidebarheader>.card-header,
.sidebarheader2>.card-header {
    border: none !important;
    background-color: transparent !important;
    color: white;
    font-size: 16px !important;
}

.sidebarheader2>div {
    padding: 0.5rem 0;
}

.sidebarheaderarrow {
    height: .7em;
    width: .7em;
    margin-right: 20px;
    margin-top: 8px;
}

#sidebarcont .sidebarcardbody {
    background-color: #011938 !important;
}

.sidebarcardbody {
    padding-left: 17% !important;
    background-color: #011938 !important;
}

.sidebarcardbody>p {
    color: #c1ccd3;
    font-size: 10px;
}

.sidebarcardbody>a {
    font-size: 16px !important;
    color: white !important;
    text-decoration: none;
}

.sidebarcardbody>hr {
    border-top: 1px solid #c1ccd3;
}

.sidebarcardbody>a:hover {
    text-decoration: underline;
}

.sidebarinactive {
    cursor: default;
}

.sidebarinactive:hover {
    text-decoration: none !important;
}

.sidebarlinkicon {
    margin-bottom: 6px;
    margin-left: 5px;
    height: 14px;
}

.sidebarbetalabel {
    font-size: 10px;
    background: #FFE867;
    color: black;
    margin-left: 5px;
    padding: 0 5px;
    font-weight: 700;
    font-family: "AileronRegular", "OpenSansRegular";
}

.sidebaravailablelabel {
    font-size: 10px;
    background: #B2F9FC;
    color: black;
    margin-left: 5px;
    padding: 0 5px;
    font-weight: 700;
    font-family: "AileronRegular", "OpenSansRegular";
}

.sidebarstillbuildinglabel {
    font-size: 10px;
    background: #dcdcdc;
    color: black;
    margin-left: 5px;
    padding: 0 5px;
    font-weight: 700;
    font-family: "AileronRegular", "OpenSansRegular";
}