/* scrollbar */
* {
  scrollbar-width: auto;
  scrollbar-color: #c1ccd3 #edeff2;
}

*::-webkit-scrollbar {
  width: 15px;
}

*::-webkit-scrollbar-track {
  background: #edeff2;
}

*::-webkit-scrollbar-thumb {
  background-color: #c1ccd3;
  border-radius: 20px;
  border: 3px solid #edeff2;
}