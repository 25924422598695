.filter-dropdown {
    width: 20% !important;
}

#vertical-charts {
    width: 1100px;
    height: 1300px;
}

.progressbardivfilters {
    padding-bottom: 1.25rem;
}
    
.progressbardivfilters > div {
    height: 0.3rem;
    background-color: white;
    border-radius: .35rem;
}

#notes-points {
    margin-bottom: 0.5rem;
}

#notes-points a {
    color: #039be5 !important;
}

#notes-points a:hover {
    color: #039be5 !important;
    text-decoration: underline;
}

@media only screen and (max-width: 425px) {
    #vertical-charts {
        width: 450px !important;
        height: 1300px !important;
    }
}

@media only screen and (min-width: 426px) and (max-width: 576px) {
    #vertical-charts {
        width: 450px !important;
        height: 1300px !important;
    }
}

@media only screen and (min-width: 577px) and (max-width: 768px) {
    #vertical-charts {
        width: 450px !important;
        height: 1300px !important;
    }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
    #vertical-charts {
        width: 600px !important;
        height: 1300px !important;
    }
}

@media only screen and (min-width: 1025px) and (max-width: 1440px) {
    #vertical-charts {
        width: 850px !important;
        height: 1300px !important;
    }
}

@media only screen and (min-width: 1441px) {
    #vertical-charts {
        width: 1200px !important;
        height: 1300px !important;
    }
}