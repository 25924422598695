#referral {
    width: 100%;
    background-color: #eff8ff;
    min-height: 120vh;
    position: relative;
    font-family: "AileronRegular", "OpenSansRegular";
}

.referralcardheadermain {
    color: #1f5f8b !important;
    font-weight: bold;
    font-size: 18px;
    font-family: "AileronRegular", "OpenSansRegular" !important;
}

.referralcardheader {
    color: #1f5f8b !important;
    font-weight: bold;
    font-size: 18px;
    font-family: "AileronRegular", "OpenSansRegular" !important;
}

.referralcard {
    height: 400px;
}

.referralselect {
    font-size: 16px;
    width: fit-content !important;
    margin: 0.6rem 0.75rem 0.4rem 0.75rem;
    border: transparent !important;
}

.referralinfo {
    height: 0.5rem;
    width: 0.5rem;
}

.referraltabs {
    font-size: 16px !important;
    border-bottom: 1px solid #cdcdcd !important;
    margin: 15px 15px 40px 15px !important;
}

.referraltabs>a {
    background-color: transparent !important;
    color: #29323a !important;
    border-bottom: 1px solid #cdcdcd !important;
}

/* invite component */

.referralinvitediv {
    height: 220px;
    overflow-y: auto;
    padding: 0 15px 0 15px;
}

.referralinvitebottom {
    display: flex;
    padding: 6px 0;
}

.referralinviteemaildiv {
    display: flex;
}

.referralinvitesendbutton {
    background-color: #0065ff !important;
    color: white !important;
    border: 1px solid #0065ff !important;
    padding: 6px 15px !important;
    margin: auto auto auto 15px !important;
    font-weight: bold !important;
    font-family: "AileronRegular", "OpenSansRegular" !important;
    font-size: 14px !important;
    border-radius: 5px !important;
}

.referralinvitegeneratebutton {
    background-color: #0065ff !important;
    color: white !important;
    border: 1px solid #0065ff !important;
    padding: 6px 15px !important;
    margin: 0.6rem auto 0.4rem 15px !important;
    font-weight: bold !important;
    font-family: "AileronRegular", "OpenSansRegular" !important;
    font-size: 14px !important;
    border-radius: 5px !important;
}

/* rewards component */

.referralrewardsdiv {
    height: 245px;
    overflow-y: auto;
    display: flex;
}

.referralrewardsdiv>div {
    width: 50%;
    padding: 0 15px;
}

.referralrewardsheader {
    font-size: 12px;
    margin-bottom: 0;
}

.referralrewardsdata {
    font-size: 16px;
    font-weight: bold;
}

/* faq component */

.referralfaqaccordion {
    height: 245px;
    overflow-y: auto;
}

.referralfaqaccordion>.card {
    border: 1px solid transparent !important;
}

.referralfaqheader {
    display: flex;
    font-size: 14px !important;
    background-color: white !important;
    padding: .75rem 1.25rem;
    border-bottom: none;
    color: grey;
    border: none !important;
    margin: 0 !important;
    font-weight: bold !important;
    font-family: "AileronRegular", "OpenSansRegular" !important;
    border-radius: 5px !important;
    width: 100%;
}

.referralfaqheader:hover {
    background-color: #edeff2 !important;
}

.referralfaqtext {
    width: 90%;
}

.referralfaqarrow {
    width: 10%;
    margin-top: -2px;
    text-align: right;
}

/* perform component */

.referralperformdiv {
    height: 220px;
    overflow-y: auto;
    display: flex;
    padding: 0 15px 0 15px;
}

.referralperformdiv>div {
    width: 50%;
    padding: 0 15px;
}

/* impact component */

.referralimpactdiv {
    height: 245px;
    overflow-y: auto;
    padding: 0 15px;
}

.referralimpacttable {
    border-collapse: collapse;
    width: 100%;
    font-size: 14px !important;
}

.referralimpacttable tr {
    border: 1px solid #dee2e6;
    border-width: 1px 0;
    height: 50px;
}

.referralimpacttable>thead>tr:first-child {
    border-top: none;
}

.referralimpacttable>tbody>tr:last-child {
    border-bottom: none;
}

.referralimpacttable>td {
    width: 25%;
}

.referralimpactlastupdate {
    font-size: 12px;
    text-align: right;
    width: -webkit-fill-available;
    align-self: end;
    padding-right: 15px;
}

/* media breakpoints */

@media only screen and (max-width: 576px) {
    #referralinvite.referralcard {
        height: 550px;
    }

    #referralperform.referralcard {
        height: 450px;
    }

    #referralimpact.referralcard {
        height: 500px;
    }

    .referralinvitebottom {
        display: inline;
    }

    .referralimpactdiv {
        overflow-x: auto;
    }

    .referralimpacttable {
        width: 200%;
    }
}

@media only screen and (max-width: 1400px) {
    .referralcardheader {
        padding-top: 40px;
    }
}