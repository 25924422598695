#profile {
    background-color: aliceblue;
    width: 100%;
    min-height: 120vh;
    margin: 0px;
    font-family: "AileronRegular", "OpenSansRegular";
}

.referraltitletext {
    font-family: "AileronRegular", "OpenSansRegular";
    text-align: left;
    font-size: 28px;
    font-weight: bold;
}

.referralwidget {
    display: block;
    position: relative;
    margin-bottom: 30px;
    padding: 15px 20px;
    background: #fff;
    border-radius: 0.35rem;
    box-shadow: 0 23px 20px -20px rgba(0, 0, 0, 0.1), 0 0 15px rgba(0, 0, 0, 0.06);
    padding: 40px;
}

.referraliconbutton {
    margin: 32px 0 0 5px;
    height: 41px;
    background-color: transparent !important;
    border: none !important;
    color: black !important;
}

.referraliconbutton:hover {
    background-color: gainsboro !important;
    border: none !important;
    color: black !important;
}

.referraltablenoborder {
    border-collapse: collapse;
}
  
.referraltablerow { 
    border: 1px solid #dee2e6;
    border-width: 1px 0;
    height: 50px;
    font-size: 16px;
}
  
.referraltablerow:first-child {
    border-top: none;
}
  
.referraltablerow:last-child {
    border-bottom: none;
}
  
.referraltabledata:first-child {
    width: 35vw;
}
  
.referraltabledata:last-child {
    width: 15vw;
}
  
.referraltablecolmiddle {
    width: 50vw;
}